.hero-container {
  background: url("/public/images/background-home.jpg") center center/cover
    no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.hero-container > h1 {
  text-shadow: 1px 4px 2px #52351d;
  color: #fff;
  font-size: 70px;
  /* margin-top: -100px; */
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-style: italic;
  text-shadow: 1px 1px 2px #413ba7;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 1340px) {
  .hero-container > h1 {
    font-size: 60px;
    text-align: center;
  }
}
@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    /* margin-top: -100px; */
    margin-right: 10px;
    margin-left: 10px;
  }

  .hero-container > p {
    font-size: 24px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
