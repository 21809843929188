.abo-main-container {
  background: rgba(130, 51, 182);
  background: linear-gradient(153deg, #8233b6 0%, #b01313 50%, #fcb045 100%);
  min-height: 120vh;
  min-width: 100%;
  justify-content: center;
}
.abo-card__container {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  list-style: none;
  justify-content: center;
  margin: 0;
  padding: 0;
}
.abo-card__item {
  display: flex;
  padding: 1rem;
}
@media (min-width: 40rem) {
  .abo-card__item {
    width: 50%;
  }
}
@media (min-width: 56rem) {
  .abo-card__item {
    width: 33.3333%;
  }
}
.abo-card {
  background-color: rgba(87, 85, 85, 0.418);
  border-radius: 0.45rem;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.98);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-basis: 100%;
  flex-grow: 1;
}
.abo-card:hover {
  cursor: pointer;
}
.abo-card:hover .abo-card__image {
  filter: contrast(110%);
  filter: saturate(150%);
}
.abo-card__content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1rem;
}
.abo-card__title {
  color: #ffffff;
  text-align: center;
  text-shadow: 1px 3px 2px #52351d;
  font-size: 1.95rem;
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.abo-card__text {
  flex: 1 1 auto;
  font-size: 1rem;
  font-weight: 10;
  font-style: auto;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #ffffff;
  line-height: 1.5;
  margin-bottom: 1.25rem;
}
.abo-card__subtext {
  flex: 1 1 auto;
  flex-direction: column;
  font-weight: 5;
  font-style: italic;
  font-size: smaller;
  font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #ffffff;
  line-height: 1.1;
  margin-bottom: 0.25rem;
}
.card__btn {
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 0.5rem;
  font-size: 1rem;
  color: rgba(20, 20, 20, 0.541);
  padding: 0.5rem;
  text-transform: none;
}
.card__btn:hover {
  cursor: pointer;
  background-color: rgba(209, 208, 208, 0.4);
  color: #ffffff;
  transition: 100ms;
}
.card__btn--block {
  display: block;
  width: 100%;
}
.abo-card__image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  filter: contrast(100%);
  overflow: hidden;
  position: relative;
  transition: filter 0.5s cubic-bezier(0.43, 0.41, 0.22, 0.91);
}
.abo-card__image::before {
  content: "";
  display: block;
  padding-top: 56.25%;
}
@media (min-width: 40rem) {
  .abo-card__image::before {
    padding-top: 66.6%;
  }
}
.card__image--lignesfixes {
  background-image: url("/public/images/lignesfixes.jpeg");
}
.card__image--lignesmobiles {
  background-image: url("/public/images/lignesmobiles.jpg");
}
.card__image--ipbx {
  background-image: url("/public/images/ipbx.png");
}
.card__image--pabx {
  background-image: url("/public/images/pabx.jpg");
}
.card__image--recycle {
  background-image: url("/public/images/recy.jpg");
}
.card__image--ftth {
  background-image: url("/public/images/ftth.jpg");
}
.card__image--ftto {
  background-image: url("/public/images/ftto.jpg");
}
.card__image--adsl {
  background-image: url("/public/images/adsl.jpeg");
}
.card__image--express {
  background-image: url("/public/images/express.jpg");
}
.card__image--who {
  background-image: url("/public/images/who.jpg");
}
.card__image--centrex {
  background-image: url("/public/images/centrex.png");
}
