.main-container {
  background: rgb(130, 51, 182);
  background: linear-gradient(
    153deg,
    rgba(130, 51, 182, 1) 0%,
    rgba(176, 19, 19, 1) 50%,
    rgba(252, 176, 69, 1) 100%
  );
  min-height: 110vh;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  /* padding-top: 100px;
  padding-bottom: 100px; */
}

.main-container > h1 {
  text-shadow: 1px 1px 2px rgb(0, 0, 0);
  margin-top: 5%;
  margin-bottom: 5%;
  color: #ffffff;
  font-size: 63px;
  font-style:normal;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
  padding: 10px 50px 10px 50px;
  min-width: 70%;
  text-align: center;
}

.sub-container {
  display: flex;
  flex-direction: column;
  max-width: 65%;

  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;

  justify-content: center;
  align-items: center;

  border: 1px solid #52351d;
  border-radius: 2ch;

  padding: 30px 100px 30px 100px;
  margin-bottom: 5%;

  background: #2b155c;
  box-shadow: 10px 5px 5px 	rgb(83, 40, 177,0.3);
}

.sub-container > p {
  color: #ffffff;
  font-weight: lighter;
  font-size: 20px;
  text-align: justify;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  padding: 20px 10px 20px 10px;
}
