.filler {
  height: 90px;
}

.solbar {
    /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
    background-color:	rgba(194, 191, 191, 0.95);
    padding-left: 5%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    top: 90px;
    position: sticky;
    z-index: 9;
  }
  
  .solbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1600px;
    width : 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .solbar-logo {
    color: #f78414;
    justify-self: start;
    margin-left: 10px;
    min-width: 280px;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.7rem;
    display: table;
    align-items: center;
  }

  .solbar-logo:hover{
    color: #f81a82;
    transition: color 0.2s;
  }
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .sol-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 100%;
    justify-content: start;
    margin-right: 2rem;
  }
  
  .sol-item {
    height: 80px;
  }

  .sol-links.active {
    color : rgb(255, 102, 0);
  }
  
  .sol-links {
    color: #5a2413;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .sol-links:hover {
    color : #f81a1a;
    transition: all 0.1s ease;
  }
  
  .fa-bars {
    color: #fff;
  }

