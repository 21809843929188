:root {
    --primary: #413ba7;
    --secondary: rgb(199, 129, 70);
    --text : #ffffff;
  }
  
  .btn {
    padding: 4px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    min-width: 110px;
    max-height: 60px;
    margin: 4px;

  }
  
  .btn--primary {
    background-color: var(--primary);
    color: var(--text) ;
    border: 1px solid var(--primary);
  }

  .btn--secondary {
    background-color: var(--secondary);
    color: var(--text) ;
    border: 1px solid var(--secondary);
  }
  
  .btn--outline {
    background-color: transparent;
    color: var(--primary);
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
  }
  
  .btn--medium {
    padding: 8px 20px;
    font-size: 18px;
  }
  
  .btn--large {
    padding: 12px 26px;
    font-size: 20px;
  }
  
  .btn--primary:hover {
    transition: all 10s ease-out;
    background-color: var(--primary);
    color: var(--secondary);
    transition: 250ms;
  }

  .btn--secondary:hover {
    transition: all 10s ease-out;
    color: black;
    transition: 250ms;
  }

  .btn--outline:hover {
    transition: all 10s ease-out;
    color: whitesmoke;
    border-color: whitesmoke;
    transition: 250ms;
  }